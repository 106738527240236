import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import Toggle from "./Toggle";

import { rhythm, scale } from "../utils/typography";
import sun from "../assets/sun.png";
import moon from "../assets/moon.png";

class Layout extends React.Component {
	constructor(props) {
		super(props);
		this.state = { theme: null };
	}

	componentDidMount() {
		this.setState({ theme: window.__theme });
		window.__onThemeChange = () => {
			this.setState({ theme: window.__theme });
		};
	}

	renderHeader() {
		const { location, title } = this.props;
		const rootPath = `${__PATH_PREFIX__}/`;

		if (location.pathname === rootPath) {
			return (
				<h1
					style={{
						...scale(0.75),
						marginBottom: 0,
						marginTop: 0,
					}}
				>
					<Link
						style={{
							boxShadow: "none",
							textDecoration: "none",
							color: "var(--textTitle)",
						}}
						to="/"
					>
						{title}
					</Link>
				</h1>
			);
		}
		return (
			<h3
				style={{
					fontFamily: "Montserrat, sans-serif",
					marginTop: 0,
					marginBottom: 0,
					height: 42, // because
					lineHeight: "2.625rem",
				}}
			>
				<Link style={{ boxShadow: "none", textDecoration: "none", color: "#3EACA8" }} to="/">
					{title}
				</Link>
			</h3>
		);
	}

	render() {
		const { children } = this.props;
		const { theme } = this.state;
		return (
			<div
				style={{
					color: "var(--textNormal)",
					background: "var(--bg)",
					transition: "color 0.2s ease-out, background 0.2s ease-out",
					minHeight: "100vh",
				}}
			>
				<Helmet
					meta={[
						{
							name: "theme-color",
							content: theme === "light" ? "#ffa8c5" : "#282c35",
						},
					]}
				/>
				<div
					style={{
						marginLeft: "auto",
						marginRight: "auto",
						maxWidth: rhythm(24),
						padding: `2.625rem ${rhythm(3 / 4)}`,
					}}
				>
					<header
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							marginBottom: "2.625rem",
						}}
					>
						{this.renderHeader()}
						{theme !== null ? (
							<Toggle
								icons={{
									checked: (
										<img
											alt="dark"
											src={moon}
											width="16"
											height="16"
											role="presentation"
											style={{ pointerEvents: "none" }}
										/>
									),
									unchecked: (
										<img
											alt="light"
											src={sun}
											width="16"
											height="16"
											role="presentation"
											style={{ pointerEvents: "none" }}
										/>
									),
								}}
								checked={theme === "dark"}
								onChange={(e) => window.__setPreferredTheme(
									e.target.checked ? "dark" : "light",
								)}
							/>
						) : (
							<div style={{ height: "24px" }} />
						)}
					</header>
					{children}
				</div>
			</div>
		);
	}
}

export default Layout;
